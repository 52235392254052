<template lang='pug'>
include ../../../configs/template
div
  v-card-title(v-if="!this.$route.params?.documentID")
    v-tabs(v-model="activeTab" color="primary" grow)
      v-tab(v-for="tab in tabs" :key="tab.id") {{$t(tab.label)}}
  v-divider

  v-card-text(v-if="!activeTab").row.pt-4
    div.col-sm-12.col-md-6.py-1
      +select-validation('body.country', 'listCountry', 'country', '"value"', '["required"]')(item-value="id")
    div(v-if="body.country === COUNTRY_CONSTANTS.UKRAINE").col-sm-12.col-md-6.py-1
      +select-validation('body.port', 'listPort', 'port', 'nameLang', '["required"]')(item-value="id")
    div(v-else).col-sm-12.col-md-6.py-1
      +field-validation('body.other_port', 'port', '["required"]')
    div.col-sm-12.col-md-6.py-1
      +field-validation('body.number_document', 'number', '["required", "maxLength"]')
    div(v-if="body.country === COUNTRY_CONSTANTS.UKRAINE").col-sm-12.col-md-6.py-1
      +field-validation('body.captain', 'issuedBy', '["required", "maxLength"]')
    div(v-else).col-sm-12.col-md-6.py-1
      +field-validation('body.captain', 'captain', '["required", "maxLength"]')
    div.col-sm-12.col-md-4.py-1
      +date-picker-validation('body.date_start', 'dateIssue', 'dateStartObject', '["required", "minValue", "maxValue"]')
    div.col-sm-12.col-md-4.py-1
      +date-picker-validation('body.date_end', 'dateTermination', 'dateEndObject', '["required", "minValue", "maxValue"]')
    div(v-if="actionType === 'adding' || checkAccess('sailorPassport', 'showDateRenewal', sailorPassport)").col-sm-12.col-md-4.py-1
      +date-picker-validation('body.date_renewal', 'dateRenewal', 'dateRenewalObject', '["minValue", "maxValue"]')

  v-card-text(v-else)
    div.py-1
      +field-validation('body.number_document', 'number', '["required"]')
    div.py-1
      +select-validation('body.statement', 'listApproveStatement', 'approvedStatements', '"fullName"', '["required"]')(return-object)
    div(v-if="body.statement && body.statement.is_continue").py-1
      +select-validation('body.passport', 'listAllowToContinuePassport', 'continuingStatement', '"fullName"', '["required"]')(item-value="id")
  v-card-text
    FileDropZone(ref="mediaContent").w-100

  v-card-actions.justify-center.pb-4
    v-btn(color="success" @click="saveSailorPassport" :loading="isLoading") {{ $t('btn.save') }}
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { sailorPassportValidation } from '@/mixins/validationRules.js'
import { checkAccess } from '@/mixins/permissions'
import { clearBody } from '@/mixins/main'
import { COUNTRY_CONSTANTS, SUCCESS_CODE } from '@/configs/constants'

const initBody = () => (
  {
    country: 2,
    number_document: null,
    port: null,
    other_port: null,
    captain: null,
    date_start: null,
    date_end: null,
    date_renewal: null,
    statement: null,
    passport: null
  }
)
export default {
  name: 'SailorPassportForm',
  components: {
    FileDropZone: () => import('@/components/atoms/DropZone/DropZone.vue')
  },
  props: {
    actionType: { type: String, default: 'adding' },
    sailorPassport: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      activeTab: 0,
      isLoading: false,
      COUNTRY_CONSTANTS,
      body: initBody(),
      tabs: [
        { id: 1, label: 'addExistSailorPassport', checkAccess: () => checkAccess('sailorPassport', 'addExistPassport') },
        { id: 2, label: 'addNewSailorPassport', checkAccess: () => checkAccess('sailorPassport', 'addNewPassport') }
      ],
      checkAccess
    }
  },
  computed: {
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      listCountry: state => state.directory.country,
      listPort: state => state.directory.ports,
      listApproveStatement: state => state.sailor.approvedSailorPassportStatements,
      listAllowToContinuePassport: state => state.sailor.existSailorPassports
    }),
    labelCaptain () {
      return this.body.country === COUNTRY_CONSTANTS.UKRAINE ? 'issuedBy' : 'captain'
    },
    dateStartObject () {
      return this.body.date_start ? new Date(this.body.date_start) : null
    },
    dateEndObject () {
      return this.body.date_end ? new Date(this.body.date_end) : null
    },
    dateRenewalObject () {
      return this.body.date_renewal ? new Date(this.body.date_renewal) : null
    }
  },
  validations () { return sailorPassportValidation(this) },
  mounted () {
    if (this.$route.params?.documentID) {
      Object.keys(this.body).forEach(key => (this.body[key] = this.sailorPassport[key]))
    }
  },
  methods: {
    ...mapActions(['setSailorPassport', 'setSailorPassportWithStatement', 'updateSailorPassportById']),
    async saveSailorPassport () {
      if (this.$v.$invalid) return this.$v.$touch()
      this.isLoading = true
      let data = {
        id: this.$route.params.id,
        body: { ...clearBody({ ...this.body }, ['date_renewal']) },
        media: {
          files: [...this.$refs.mediaContent.filesArray],
          file_type: 'seafarer_pass_doc'
        }
      }
      data.body.statement && (data.body.statement = data.body.statement.id)
      let response = {}
      if (this.$route.params?.documentID) {
        response = await this.updateSailorPassportById({ ...data, ...this.$route.params })
      } else if (this.activeTab) response = await this.setSailorPassportWithStatement(data)
      else response = await this.setSailorPassport(data)
      if (SUCCESS_CODE.includes(response.code)) {
        this.body = initBody()
        !this.$route?.documentID && (this.$parent.isViewAddSlot = !this.$parent.isViewAddSlot)
        this.$v.$reset()
      }
      this.isLoading = false
    }
  }
}
</script>
